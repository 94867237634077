import { Component, OnInit } from '@angular/core';
import { MainService } from '../service/main.service';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  fileName: String = ''; // file name selected
  files: File = null; // file selected
  hasFile: Boolean = false; // to enable/disable submit button
  uploadType = 'branch'; // type to upload

  selectedFileUploadType = 'file'; // selct file type to upload
  isCSVselected: Boolean = true;
  csvFile: any = null;

  payload = '';
  decodeExpireSession: any;

  isLogin;
  now;

  uploadId: number;
  resTable: string;

  loadingLogin = false;


  constructor(private mainService: MainService) { }

  ngOnInit() {

    this.mainService.loginClicked.subscribe(isLogin => {
      this.isLogin = isLogin;
      //   if (this.isLogin) {
      this.payload = sessionStorage.getItem('payload');
      // console.log(this.payload);
      this.getDecodedAccessToken(this.payload);
      //   }
    });

    this.checkExpireSession();

  }

  getDecodedAccessToken(token: string): any {
    try {
      let decode;
      decode = jwt_decode(token);
      // console.log(decode);
      this.decodeExpireSession = moment(decode.date_expired);
      // console.log('DECODE', this.decodeExpireSession);
    } catch (Error) {
      return null;
    }
  }

  checkExpireSession() {

    // this.now = moment();

    // if (this.now.isBefore(this.decodeExpireSession)) {
    //   this.mainService.loginClicked.next(true);
    //   console.log('NOW', this.now);
    //   console.log('DECODE', this.decodeExpireSession);
    //   console.log(1111);
    // } else {
    //   console.log(2222);
    //   console.log('NOW', this.now);
    //   console.log('DECODE', this.decodeExpireSession);
    //   sessionStorage.removeItem('payload');
    //   this.mainService.loginClicked.next(false);
    // }

    if (sessionStorage.getItem('payload')) {
      this.mainService.loginClicked.next(true);
      // console.log(1111);
    } else {
      // console.log(2222);
      this.mainService.loginClicked.next(false);
    }

  }

  fileTypeSelected(type: string) {
    this.selectedFileUploadType = type;
    if (this.selectedFileUploadType === 'file') {
      this.isCSVselected = true;
      (<HTMLInputElement>document.getElementById('button')).disabled = true; // enable submit button
    } else {
      this.isCSVselected = false;
      (<HTMLInputElement>document.getElementById('button')).disabled = false; // enable submit button
    }
  }

  typeChosen(value) {
    this.uploadType = value;
  }

  // select file to upload
  selectFile(filesUpload: FileList) {
    if (filesUpload && filesUpload.length > 0) { // if has file set hasFile is true
      this.fileName = filesUpload[0].name; // display file name on view
      this.files = filesUpload.item(0);

      this.hasFile = true;
      (<HTMLInputElement>document.getElementById('button')).disabled = false; // enable submit button
    }
  }

  // submit file to service
  uploadFile() {
    this.loadingLogin = true;
    // check if file is not undifined
    if (this.files || this.selectedFileUploadType === 'url') {
      if (confirm('Upload ' + this.uploadType + ' success.' + ' Do you want to confirm?\n' + '*THIS ACTION CANNOT BE UNDONE')) {
        // show confirm dialog
        this.mainService.setCSVfileFromAdmin(this.uploadType, this.files, this.selectedFileUploadType).subscribe((res: any) => {
          console.log('ressss', res);
          this.uploadId = res.id;
          this.resTable = res.table;

          this.mainService.afterUpload(this.uploadId, this.resTable);
          this.loadingLogin = false;

          this.finishedUpload();
        });

      } else {
        // Do nothing!
      }
    }
  }

  finishedUpload() {
    if (confirm('Upload complete')) {
    }
  }

  // remove selected file
  cancleSelectedFile() {
    this.files = null;
    this.hasFile = false;
    this.fileName = '';
    (<HTMLInputElement>document.getElementById('button')).disabled = true; // disable submit button
  }

  toLogout() {
    sessionStorage.removeItem('payload');
    this.mainService.loginClicked.next(false);
  }

}
