import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';

@Component({
  selector: 'app-search-this-area',
  templateUrl: './search-this-area.component.html',
  styleUrls: ['./search-this-area.component.css']
})
export class SearchThisAreaComponent implements OnInit {

  isSearchThisArea: Boolean = false;

  constructor(private mainService: MainService) { }

  ngOnInit() {
  }

  searchThisArea() {
    this.isSearchThisArea = true;
    this.mainService.toSearchThisArea.next(this.isSearchThisArea);
  }

}
