import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';

@Component({
  selector: 'app-no-data-modal',
  templateUrl: './no-data-modal.component.html',
  styleUrls: ['./no-data-modal.component.css']
})
export class NoDataModalComponent implements OnInit {

  isSearchThisAreaClicked: Boolean = false;

  constructor(private mainService: MainService) { }

  ngOnInit() {
    this.onSearchThisAreaClicked(); // show if no data
  }

  onSearchThisAreaClicked() {
    this.mainService.toShowNoDataModal.subscribe(isSearchThisArea => {
      this.isSearchThisAreaClicked = isSearchThisArea;
      if (this.isSearchThisAreaClicked === true) {
        setTimeout(() => {
          this.isSearchThisAreaClicked = false;
        }, 2000);
      }
    });
  }

  toCloseModel() {
    this.isSearchThisAreaClicked = false;
  }

}
