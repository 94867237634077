import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './app.overlay.component.html',
  styleUrls: ['./app.overlay.component.css']
})
export class AppOverlayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
