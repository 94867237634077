import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  selectedType = new BehaviorSubject<any[]>([]); // store selected type from filter panel
  toHideandShowPanel = new BehaviorSubject<Boolean>(false); // for left side panel (filter)
  toShowInfowindow = new BehaviorSubject<Boolean>(false); // whether show or hide infowindow
  toKeepInfoData = new BehaviorSubject<any[]>([]); // set of data for infowindow (mobile)
  initialLocation = new BehaviorSubject<any>(null); // center location of marker
  sendType = new BehaviorSubject<String>(''); // selected type from right map
  defaultLang = new BehaviorSubject<string>(''); // default language from kbank
  sendLat = new BehaviorSubject<number>(0); // send lat to search this area
  sendLng = new BehaviorSubject<number>(0); // send lng to search this area
  toSearchThisArea = new BehaviorSubject<Boolean>(false); // is search this area button clicked
  toShowNoDataModal = new BehaviorSubject<Boolean>(false); // to show no data modal
  inDataLoaded = new BehaviorSubject<Boolean>(false);
  loginClicked = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  // get initial location and nearby data for both Branch and ATM
  // by send chosenId and chosen type from kbank site
  public getChosenLocation(id: String, type: String) {
    const path = 'https://api-dot-kbank-locator.appspot.com/location/nearby?q=' + id + '&type=' + type + '&';
    return this.http.get(path)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  // get search this area data
  public getDataFromSearchThisArea(lat: Number, lng: Number) {
    const path = 'https://api-dot-kbank-locator.appspot.com/location/nearby/this_area?lat=' + lat + '&lng=' + lng;
    return this.http.get(path)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  // post upload file for admin page
  public setCSVfileFromAdmin(type: string, fileToUpload: File, sourceUpload: string) {
    const username = 'admin';
    const password = 'HappyDay100%';
    const path = 'http://35.240.202.38/location/upload';

    const formData: FormData = new FormData();

    sourceUpload === 'file' ? formData.append('file', fileToUpload, fileToUpload.name) : console.log(sourceUpload);
    formData.append('source', sourceUpload);
    formData.append('table', type);

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic ' + btoa(`${username}:${password}`));
    // headers = headers.append('Content-Type', 'multipart/form-data');

    return this.http.post(path, formData, { headers });
  }

  public afterUpload(id: number, table: string) {
    const username = 'admin';
    const password = 'HappyDay100%';
    const path = 'http://35.240.202.38/location/upload/publish';

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic ' + btoa(`${username}:${password}`));

    return this.http.post(path, {location_upload_id: id, table: table, published: true}, { headers })
    .subscribe(res => console.log('ressss', res),
      error => console.log(error));
  }

  // geolocation to get current location
  public getPosition(): Observable<Position> {
    return Observable.create(
      (observer) => {
        navigator.geolocation.watchPosition((pos: Position) => {
          observer.next(pos);
        }),
          // tslint:disable-next-line:no-unused-expression
          () => {
            console.log('Position is not available');
          },
          // tslint:disable-next-line:no-unused-expression
          {
            enableHighAccuracy: true
          };
      });
  }

  setLogin(username: string, password: string) {
    const path = 'https://api-dot-kbank-locator.appspot.com/admin/login';
    const body = {};

    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic ' + btoa(`${username}:${password}`));

    return this.http.post(path, body, { headers });
  }

  // handle error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}

