import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isLogin = false;
  showErrorMessage = false;
  loading = true;
  loadingLogin = false;

  username: string;
  password: string;

  payload = '';

  constructor(private mainService: MainService) { }

  ngOnInit() { }

  usernameChangeEvent(inputValue: string) {
    inputValue.length > 0 ? this.loading = false : this.loading = true;
  }

  toLogin(username, password) {
    this.loadingLogin = true;

    this.mainService.setLogin(username, password)
      .subscribe(res => {
        this.payload = JSON.stringify(Object.values(res));
        sessionStorage.setItem('payload', this.payload);
        this.mainService.loginClicked.next(true);
      }, error => {
        this.showErrorMessage = true;
        this.loadingLogin = false;
        console.log(error);
      });
  }

  keytab(event: any) {
    const element = event.srcElement.nextElementSibling.nextElementSibling;
    element.focus();
  }

}
