import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { MainService } from '../service/main.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  showOverlay: Boolean = false;
  showFilterPanel: Boolean = false;
  isLoading = false;

  currentLat: number;
  currentLng: number;
  coordinates: { latitude: number; longitude: number; };

  constructor(private mainService: MainService) { }

  ngOnInit() {
    this.mainService.inDataLoaded.subscribe(isLoaded => {
      if (isLoaded) {
        this.loadingPage();
      }
    });
  }

  // animate loading progress when page load
  loadingPage() {
    const elem = document.getElementById('myBar');
    let width = 10;
    const id = setInterval(() => {
      if (width >= 100) {
        width = 0;
        setTimeout(() => {
          this.isLoading = true;
        }, 200);
        clearInterval(id);
      } else {
        width++;
        elem.style.width = width + '%';
      }
    }, 10);
  }


  // for show of hide overlay
  onBurgerClick(e) {
    if (window.innerWidth <= 1024) {
      this.showOverlay = e;
    }
  }

  // to toggle filter panel
  toggleFilterPanel() {
    this.showFilterPanel = false;
    this.mainService.toHideandShowPanel.next(this.showFilterPanel);
  }
}
