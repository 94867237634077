import { Component, OnInit } from '@angular/core';
import { MainService } from '../../service/main.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-right-map',
  templateUrl: './right-map.component.html',
  styleUrls: ['./right-map.component.css']
})

export class RightMapComponent implements OnInit {

  greedy = 'greedy';
  screenWidth: any;
  fullscreenButton: Boolean = true;
  streetviewButton: Boolean = true;
  zoomButton: Boolean = true;
  isWidthHigherThanIpad: Boolean = false;

  test: Boolean = false;

  shouldShowBranchMarker: Boolean;
  shouldShowATMMarker: Boolean;
  shouldShowWisdomMarker: Boolean;
  shouldShowFXMarker: Boolean;
  shouldShowSMEMarker: Boolean;
  shouldShowInterMarker: Boolean;

  isClick: Boolean = false;
  // geocoder: any;

  showInfo: Boolean = false;
  zoom: Number = 15;

  selectedType: String[] = [];
  showMarker: String = '';
  isSelectedATM: Boolean;
  isSelectedBranch: Boolean;

  infoData: any[] = [];

  lat: number = 0;
  lng: number = 0;

  telephone;
  chosenData;
  chosenId: String = '0001';
  chosenType: String = 'bb';
  chosenLang = 'th';
  // chosenId: String = 'CDM04723';
  // chosenType: String = 'bm';
  chosenTypeArray: any[] = [];
  selectedArray = [];

  branch = 'bb';
  atm = 'bm';

  nearbyTelephone;

  nearbyLatLngBranch = [{
    lat: Number,
    lng: Number
  }];

  nearbyLatLngAtmCdm = [{
    lat: Number,
    lng: Number,
  }];

  nearbyLatLngWisdom = [{
    lat: Number,
    lng: Number,
  }];

  nearbyLatLngFX = [{
    lat: Number,
    lng: Number,
  }];

  nearbyLatLngSME = [{
    lat: Number,
    lng: Number,
  }];

  nearbyLatLngInter = [{
    lat: Number,
    lng: Number,
  }];

  // chosenLatlng = {
  //   lat: Number,
  //   lng: Number,
  // };
  chosenLatlng: { lat: number, lng: number } = {
    lat: null,
    lng: null
  };


  iconBranch = {
    url: '/assets/branch.png',
    scaledSize: {
      width: 50,
      height: 50
    }
  };

  iconAtm = {
    url: '/assets/atmcdm.png',
    scaledSize: {
      width: 37,
      height: 37
    }
  };

  iconWisdom = {
    url: '/assets/the-wisdom-marker.png',
    scaledSize: {
      width: 30,
      height: 50
    }
  };

  iconFX = {
    url: '/assets/FX-test.png',
    scaledSize: {
      width: 47,
      height: 51
    }
  };

  iconSME = {
    url: '/assets/SME-test.png',
    scaledSize: {
      width: 47,
      height: 51
    }
  };

  iconInter = {
    url: '/assets/inter-test.png',
    scaledSize: {
      width: 47,
      height: 51
    }
  };

  is_atm: Boolean = false;
  is_cdm: Boolean = false;
  is_wisdom: Boolean = false;
  is_exchange: Boolean = false;
  is_sme: Boolean = false;
  is_inter: Boolean = false;

  centerLat: number = 0;
  centerLng: number = 0;

  nearbyLatLng_Search: any;

  showRecenterMarker: Boolean = false;
  hideLandingCenter: Boolean = true;
  recenterLat: number;
  recenterLng: number;

  constructor(
    private mainService_r: MainService,
    private activatedRoute: ActivatedRoute
  ) {

    this.activatedRoute.snapshot.queryParams['type'] ?
      this.chosenType = this.activatedRoute.snapshot.queryParams['type'] : this.chosenType = 'bb';

    this.activatedRoute.snapshot.queryParams['q'] ?
      this.chosenId = this.activatedRoute.snapshot.queryParams['q'] : this.chosenId = '0001';

    this.activatedRoute.snapshot.queryParams['lang'] ?
      this.chosenLang = this.activatedRoute.snapshot.queryParams['lang'] : this.chosenLang = 'th';

    // console.log(this.chosenLang, this.chosenId, this.chosenType);

    this.mainService_r.defaultLang.next(this.chosenLang);

  }

  ngOnInit() {
    // if chosen type is bb push id = 1 else push id = 2 to array
    this.chosenType === 'bb' ? this.chosenTypeArray.push(1) : this.chosenTypeArray.push(2);

    // set chosen type to show in html
    this.shouldShowBranchMarker = this.chosenType === 'bb';
    this.shouldShowATMMarker = this.chosenType === 'bm';

    // send the chosen type to service
    this.mainService_r.selectedType.next(this.chosenTypeArray);

    this.mainService_r.toSearchThisArea.subscribe(isClick => {
      this.isClick = isClick;
      if (this.isClick === true) {
        this.mainService_r.toShowNoDataModal.next(false);
        this.getSearchThisArea(); // search this area
      }
    });

    this.getLocation(); // send chosen id,type to endpoint then sub data to show
    this.getSelectedType(); // get selected type from filter panel

  }

  centerChange(event) {
    this.centerLat = event.lat;
    this.centerLng = event.lng;
  }

  // hide snazzy infowindow for mobile size
  manageScreenSize() {
    this.screenWidth = (window.innerWidth) <= 1024;
    if (this.screenWidth === true) {
      this.isWidthHigherThanIpad = false;
      this.fullscreenButton = false;
      this.streetviewButton = false;
      this.zoomButton = false;
    } else if (this.screenWidth === false) {
      // this.chosenLatlng.lng = this.chosenLatlng.lng - 0.032;
      // // console.log(this.chosenLatlng.lat, this.chosenLatlng.lng);
      this.test = true;
      this.isWidthHigherThanIpad = true;
      this.fullscreenButton = true;
    }
  }

  // get data when search this area
  getSearchThisArea() {

    if (this.centerLat !== 0) {


      this.mainService_r.getDataFromSearchThisArea(this.centerLat, this.centerLng).subscribe(searchData => {
        this.nearbyLatLng_Search = searchData['nearby'];
        // console.log(this.nearbyLatLng_Search);

        const branchs = this.nearbyLatLng_Search['branch'];
        const atms = this.nearbyLatLng_Search['atm'];

        if (this.shouldShowBranchMarker) {
          this.nearbyLatLngBranch = branchs.length > 0 ? branchs : [];
        } else {
          this.nearbyLatLngBranch = [];
        }

        if (this.shouldShowATMMarker) {
          this.nearbyLatLngAtmCdm = atms.length > 0 ? atms : [];
        } else {
          this.nearbyLatLngAtmCdm = [];
        }

        if (this.shouldShowWisdomMarker) {
          if (branchs.length > 0) {
            this.nearbyLatLngWisdom = branchs.filter(branch => branch.has_wisdom_corner.data[0] === 1);
          } else {
            this.nearbyLatLngWisdom = [];
          }
        } else {
          this.nearbyLatLngWisdom = [];
        }

        if (this.shouldShowFXMarker) {
          if (branchs.length > 0) {
            this.nearbyLatLngFX = branchs.filter(branch => branch.has_fx_booth.data[0] === 1);
          } else {
            this.nearbyLatLngFX = [];
          }
        } else {
          this.nearbyLatLngFX = [];
        }

        if (this.shouldShowInterMarker) {
          if (branchs.length > 0) {
            this.nearbyLatLngInter = branchs.filter(branch => branch.has_inter_tsc.data[0] === 1);
          } else {
            this.nearbyLatLngInter = [];
          }
        } else {
          this.nearbyLatLngInter = [];
        }

        if (this.shouldShowSMEMarker) {
          if (branchs.length > 0) {
            this.nearbyLatLngSME = branchs.filter(branch => branch.has_sme_bc.data[0] === 1);
          } else {
            this.nearbyLatLngSME = [];
          }
        } else {
          this.nearbyLatLngSME = [];
        }

        const shouldShowNoDataModal = this.nearbyLatLngAtmCdm.length < 1 && this.nearbyLatLngBranch.length < 1 &&
          this.nearbyLatLngFX.length < 1 && this.nearbyLatLngInter.length < 1 && this.nearbyLatLngSME.length < 1
          && this.nearbyLatLngWisdom.length < 1;

        this.mainService_r.toShowNoDataModal.next(shouldShowNoDataModal);

      });

      this.test = false;
      this.showRecenterMarker = true;
      this.hideLandingCenter = false;
      this.recenterLat = this.centerLat;
      this.recenterLng = this.centerLng;

      if (this.isWidthHigherThanIpad === false) { // mobile
        this.mainService_r.toShowInfowindow.next(false);
      }

    }

  }

  // send chosen id,type to endpoint then sub data to show
  getLocation() {
    this.mainService_r.getChosenLocation(this.chosenId, this.chosenType).subscribe(data => {
      this.chosenData = data;
      this.chosenLatlng = Object.assign({}, this.chosenData, this.chosenData.location);

      this.telephone = this.chosenData.location.telephone;

      this.lat = +(this.chosenData.location.lat);
      this.lng = +(this.chosenData.location.lng);
      this.nearbyLatLngBranch = this.chosenData.nearby.branch;
      this.nearbyLatLngAtmCdm = this.chosenData.nearby.atm;

      // console.log(this.chosenData);
      this.nearbyLatLngWisdom = this.chosenData.nearby.branch.filter(wisdom => wisdom.has_wisdom_corner.data[0] === 1);
      this.nearbyLatLngFX = this.chosenData.nearby.branch.filter(fx => fx.has_fx_booth.data[0] === 1);
      this.nearbyLatLngSME = this.chosenData.nearby.branch.filter(sme => sme.has_sme_bc.data[0] === 1);
      this.nearbyLatLngInter = this.chosenData.nearby.branch.filter(inter => inter.has_inter_tsc.data[0] === 1);

      this.infoData = this.chosenData.nearby.branch.map((d: any) => d);
      // send initial location to -> info window (mobile)
      this.mainService_r.initialLocation.next(this.chosenData);

      this.chosenData.location.has_atm.data[0] === 1 ? this.is_atm = true : this.is_atm = false;
      this.chosenData.location.has_cdm.data[0] === 1 ? this.is_cdm = true : this.is_cdm = false;

      if (this.chosenType !== 'bm') {
        this.chosenData.location.has_wisdom_corner.data[0] === 1 ? this.is_wisdom = true : this.is_wisdom = false;
        this.chosenData.location.has_fx_booth.data[0] === 1 ? this.is_exchange = true : this.is_exchange = false;
        this.chosenData.location.has_sme_bc.data[0] === 1 ? this.is_sme = true : this.is_sme = false;
        this.chosenData.location.has_inter_tsc.data[0] === 1 ? this.is_inter = true : this.is_inter = false;
      }

      this.mainService_r.inDataLoaded.next(true);
      this.manageScreenSize(); // hide snazzy infowindow for mobile size
      this.openWindow(); // to show/hide snazzy infowindow
    });
  }

  // get selected type from filter panel
  getSelectedType() {
    this.mainService_r.selectedType.subscribe(type => {
      if (type.length === 0) {
        this.shouldShowBranchMarker = false;
        this.shouldShowATMMarker = false;
        this.shouldShowWisdomMarker = false;
        this.shouldShowFXMarker = false;
        this.shouldShowSMEMarker = false;
        this.shouldShowInterMarker = false;
      } else {
        this.shouldShowBranchMarker = type.some(t => t.id === 1 || t === 1);
        this.shouldShowATMMarker = type.some(t => t.id === 2 || t === 2);
        this.shouldShowWisdomMarker = type.some(t => t.id === 3 || t === 3);
        this.shouldShowFXMarker = type.some(t => t.id === 4 || t === 4);
        this.shouldShowSMEMarker = type.some(t => t.id === 5 || t === 5);
        this.shouldShowInterMarker = type.some(t => t.id === 6 || t === 6);

        this.getSearchThisArea();
      }
    });
  }

  // to show/hide snazzy infowindow
  openWindow() {
    this.showInfo = !this.showInfo;
    this.mainService_r.toShowInfowindow.next(this.showInfo);
  }

  // for mobile size when marker being clicked
  onMarkerClick(e, item, type) {
    this.test = false;

    if (this.isWidthHigherThanIpad === false) { // mobile
      this.mainService_r.toKeepInfoData.next(item);
      this.mainService_r.sendType.next(type);
      this.mainService_r.toShowInfowindow.next(true);
    } else {

      if (e._id === '0') {
        this.test = true;
      }

      this.clearData();
      this.is_atm = item.has_atm.data[0] === 1 ? true : false;
      this.is_cdm = item.has_cdm.data[0] === 1 ? true : false;

      if (item.has_wisdom_corner) {
        this.is_wisdom = item.has_wisdom_corner.data[0] === 1 ? true : false;
      }
      if (item.has_fx_booth) {
        this.is_exchange = item.has_fx_booth.data[0] === 1 ? true : false;
      }
      if (item.has_inter_tsc) {
        this.is_inter = item.has_inter_tsc.data[0] === 1 ? true : false;
      }
      if (item.has_sme_bc) {
        this.is_sme = item.has_sme_bc.data[0] === 1 ? true : false;
      }

    }
  }

  clearData() {
    this.is_atm = false;
    this.is_cdm = false;
    this.is_wisdom = false;
    this.is_exchange = false;
    this.is_sme = false;
    this.is_inter = false;
  }
}
