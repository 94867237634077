import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { MainService } from '../../service/main.service';
import { Router } from '@angular/router';
import { trigger, keyframes, animate, transition } from '@angular/animations';
import * as kf from './keyframes';

@Component({
  selector: 'app-infowindow',
  templateUrl: './infowindow.component.html',
  styleUrls: ['./infowindow.component.css'],
  animations: [
    trigger('cardAnimator', [
      transition('* => slideOutDown', animate(1000, keyframes(kf.slideOutDown)))
    ])
  ]
})
export class InfowindowComponent implements OnInit, AfterViewInit {

  @Input() type: String = 'SELECTEDBRANCH';
  @Input() titleT: String = 'สาขาไทย';
  @Input() titleE: String = 'engbranch';
  @Input() chosenId: String = '';
  @Input() openStatus: String = '1';
  @Input() addressT: String = '888/8 คลองตันเหนือ เขตวัฒนา กรุงเทพมหานคร 10110';
  @Input() addressE: String = '888/8 engengeng bkk 10110';
  @Input() openHourE: String = '10:00 - 18:00 น.';
  @Input() openHourT: String = '10:00 - 18:00 น.';
  @Input() contact: String = '';

  @Input() is_atm: Boolean = false;
  @Input() is_cdm: Boolean = false;
  @Input() is_wisdom: Boolean = false;
  @Input() is_exchange: Boolean = false;
  @Input() is_sme: Boolean = false;
  @Input() is_inter: Boolean = false;

  @Input() lat: number;
  @Input() lng: number;

  currentLat: number = this.lat;
  currentLng: number = this.lng;

  router: Router;
  isOpen: Boolean = false;
  dataSet;
  screenWidth;
  markerId: Number = 0;
  coordinates: { latitude: number; longitude: number; };

  param;

  animationState: string;
  addToHtml: string[] = [];

  constructor(private mainService: MainService) { }

  ngOnInit() {
    // set whether mobile size
    this.screenWidth = (window.innerWidth) <= 1024;
    if (this.screenWidth) {
      this.mobileSize();
    } else {
      this.type === 'bb' || this.type === 'สาขา' ? this.type = 'SELECTEDBRANCH' : this.type = 'ATM/CDM';
      if (this.contact) {
        this.clickToCall();
      }
    }

    // check open status
    this.openStatus === '1' ? this.openStatus = 'OPEN' : this.openStatus = '';

    // show infowindow
    this.mainService.toShowInfowindow.subscribe(res => {
      this.isOpen = res;
    });

    this.getCurrentLocation();

    // toggle language
    this.mainService.initialLocation.subscribe(dataCenter => {
      if (dataCenter) {
        this.param = {
          title_e: dataCenter.location.branch_e, title_t: dataCenter.location.branch_t,
          address_e: dataCenter.location.address_e, address_t: dataCenter.location.address_t,
          office_hour_e: dataCenter.location.address_e, office_hour_t: dataCenter.location.office_hour_t
        };
      }
    });

    // toggle language
    this.param = {
      title_e: this.titleE, title_t: this.titleT,
      address_e: this.addressE, address_t: this.addressT,
      office_hour_e: this.openHourE, office_hour_t: this.openHourT
    };

  }

  ngAfterViewInit() {
    // we need .mar-r-7 to render on the screen first then we can get these elements.
    this.setMarR7();
  }

  setMarR7() {
    // we need .mar-r-7 to render on the screen first then we can get these elements.
    const elements = document.querySelectorAll('.mar-r-7');
    Array.from(elements).forEach(e => {
      e.setAttribute('style', 'color: #9b9b9b');
    });
  }

  clickToCall() {
    this.addToHtml = [];

    let splitTel;
    splitTel = this.contact.split(' ');

    splitTel.forEach((text: string) => {
      if (!/^[a-zA-Z]+$/.test(text)) {
        this.addToHtml.push('<a class="mar-r-7" href="tel:' + text + '">' + text + '</a>&nbsp;');
      } else {
        this.addToHtml.push('<a class="mar-r-7">' + text + '</a>&nbsp;');
      }
    });
  }

  startAnimation(state) {
    if (this.screenWidth) {
      if (!this.animationState) {
        this.animationState = state;
        setTimeout(() => {
          this.getRidOfIt();
        }, 800);
      }
    }
  }

  getRidOfIt() {
    this.isOpen = false;
  }

  resetAnimationState() {
    if (this.screenWidth) {
      this.animationState = '';
    }
  }

  // for mobile size
  mobileSize() {
    this.mainService.initialLocation.subscribe(data => { // initial location

      // check empty object of dataSet
      if (data && this.dataSet && Object.keys(this.dataSet).length === 0) {
        this.dataSet = data.location;
        this.titleT = this.dataSet.branch_t;
        this.titleE = this.dataSet.branch_e;
        this.addressT = this.dataSet.address_t;
        this.addressE = this.dataSet.address_e;
        this.lat = this.dataSet.lat;
        this.lng = this.dataSet.lng;
        this.contact = this.dataSet.telephone;
        this.openHourT = this.dataSet.office_hour_t;
        this.openHourE = this.dataSet.office_hour_e;


        const check = document.querySelector('.overflowtext')
        console.log('check', check)
        if (this.contact) {
          this.clickToCall();
          this.setMarR7();
        }

        // toggle language
        this.param = {
          title_e: this.titleE, title_t: this.titleT,
          address_e: this.addressE, address_t: this.addressT,
          office_hour_e: this.openHourE, office_hour_t: this.openHourT
        };

        // dot data to display
        this.dataSet.has_atm.data[0] === 0 ? this.type = 'SELECTEDBRANCH' : this.type = 'ATM/CDM';
        this.dataSet.has_atm.data[0] === 1 ? this.is_atm = true : this.is_atm = false;
        this.dataSet.has_cdm.data[0] === 1 ? this.is_cdm = true : this.is_cdm = false;
        this.dataSet.has_wisdom_corner.data[0] === 1 ? this.is_wisdom = true : this.is_wisdom = false;
        this.dataSet.has_fx_booth.data[0] === 1 ? this.is_exchange = true : this.is_exchange = false;
        this.dataSet.has_sme_bc.data[0] === 1 ? this.is_sme = true : this.is_sme = false;
        this.dataSet.has_inter_tsc.data[0] === 1 ? this.is_inter = true : this.is_inter = false;
      }
    });

    // nearby location
    this.mainService.toKeepInfoData.subscribe(data => {
      this.dataSet = data;
      this.titleT = this.dataSet.branch_t;
      this.titleE = this.dataSet.branch_e;
      this.addressT = this.dataSet.address_t;
      this.addressE = this.dataSet.address_e;
      this.lat = this.dataSet.lat;
      this.lng = this.dataSet.lng;
      this.contact = this.dataSet.telephone;
      this.openHourT = this.dataSet.office_hour_t;
      this.openHourE = this.dataSet.office_hour_e;

      if (this.contact) {
        this.clickToCall();
      }

      // toggle language
      this.param = {
        title_e: this.titleE, title_t: this.titleT,
        address_e: this.addressE, address_t: this.addressT,
        office_hour_e: this.openHourE, office_hour_t: this.openHourT
      };

      // dot data to display
      if (this.dataSet.length !== 0) {
        this.clearData();

        this.dataSet.has_atm.data[0] === 1 ? this.is_atm = true : this.is_atm = false;
        this.dataSet.has_cdm.data[0] === 1 ? this.is_cdm = true : this.is_cdm = false;

        if (this.dataSet.has_wisdom_corner) {
          this.dataSet.has_wisdom_corner.data[0] === 1 ? this.is_wisdom = true : this.is_wisdom = false;
        }
        if (this.dataSet.has_fx_booth) {
          this.dataSet.has_fx_booth.data[0] === 1 ? this.is_exchange = true : this.is_exchange = false;
        }
        if (this.dataSet.has_sme_bc) {
          this.dataSet.has_sme_bc.data[0] === 1 ? this.is_sme = true : this.is_sme = false;
        }
        if (this.dataSet.has_inter_tsc) {
          this.dataSet.has_inter_tsc.data[0] === 1 ? this.is_inter = true : this.is_inter = false;
        }
      }
    });

    // show or hide infowindow
    this.mainService.toHideandShowPanel.subscribe(toshow => {
      if (toshow) {
        this.isOpen = false;
      }
    });

    // chosen type
    this.mainService.sendType.subscribe(type => {
      if (type === 'สาขา') {
        this.type = 'SELECTEDBRANCH';
      } else {
        this.type = 'ATM/CDM';
      }
    });

  }

  // get current location from service
  getCurrentLocation() {
    this.mainService.getPosition().subscribe(data => {
      this.currentLat = data.coords.latitude;
      this.currentLng = data.coords.longitude;
    });
  }

  // redirect to google map
  toDirection() {
    if (this.currentLat !== 0) {
      this.redirectToGooglemap();
    } else {
      this.mainService.getPosition().subscribe(data => {
        this.currentLat = data.coords.latitude;
        this.currentLng = data.coords.longitude;
        this.redirectToGooglemap();
      });
    }
  }

  redirectToGooglemap() {
    // if (this.screenWidth) {
    // if we're on iOS, open in Apple Maps
    // if ((navigator.platform.indexOf('iPhone') !== -1) ||
    // (navigator.platform.indexOf('iPad') !== -1)) {
    window.open(`https://maps.google.com/maps?saddr=Current%20Location&daddr=${this.lat},${this.lng}&travelmode=driving`);
    // } else { // else use Google
    // window.open('https://maps.google.com/maps?saddr=Current%20Location' +
    // '&daddr=' + this.lat + ',' + this.lng + '&travelmode=driving');
    // }
    // } else { // else desktop
    // if (this.currentLat) {
    // console.log(this.currentLat);
    // window.open(`https://maps.google.com/maps?saddr=Current%20Location&daddr=${this.lat},${this.lng}&travelmode=driving`);
    // window.open('https://www.google.com/maps/dir/?api=1&origin=' + this.currentLat + ',' + this.currentLng +
    // '&destination=' + this.lat + ',' + this.lng + '&travelmode=driving');
    // } else {
    //   this.toDirection();

    // }
    // }
  }

  // close info window
  toClose() {
    this.isOpen = !this.isOpen;
  }

  // clear selected data to display
  clearData() {
    this.is_atm = false;
    this.is_cdm = false;
    this.is_wisdom = false;
    this.is_exchange = false;
    this.is_sme = false;
    this.is_inter = false;
  }
}
