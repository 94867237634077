import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MainService } from '../../service/main.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html',
  styleUrls: ['./left-side.component.css']
})
export class LeftSideComponent implements OnInit {
  @Output() burgerClick = new EventEmitter<Boolean>();

  toHide: Boolean;
  isToggle: Boolean = false;
  isSelected: Boolean = false;
  selectedOption: any[] = [];
  isThai: Boolean = true;
  isEng: Boolean = false;
  searchInput: String = '';
  chosenType: any[] = [];
  screenWidth;
  showSearchButton: Boolean = false;

  typeData = [
    { type: 'BRANCH', id: 1, isSelected: false },
    { type: 'ATM / CDM', id: 2, isSelected: false },
    { type: 'THE WISDOM', id: 3, isSelected: false },
    { type: 'FX', id: 4, isSelected: false },
    { type: 'SME', id: 5, isSelected: false },
    { type: 'INTER', id: 6, isSelected: false }
  ];

  translate: TranslateService;
  defaultLangSelected: string;

  constructor(translate: TranslateService, private mainService_l: MainService) {
    // toggle language
    this.translate = translate;
    this.translate.setDefaultLang('th');
  }

  ngOnInit() {
    // sub selected language
    this.mainService_l.defaultLang.subscribe(thoren => {
      this.defaultLangSelected = thoren;
      this.translate.setDefaultLang(this.defaultLangSelected);
    });

    this.screenWidth = (window.innerWidth) <= 1024;
    this.screenWidth ? this.showSearchButton = true : this.showSearchButton = false;

    this.toggleLanguage(this.defaultLangSelected);

    // show and hide overlay for mobile
    this.mainService_l.toHideandShowPanel.subscribe(fromOverlay => {
      this.toHide = fromOverlay;
      this.burgerClick.emit(this.toHide);
    });

    // show selected type from initial
    this.mainService_l.selectedType.subscribe(res => {
      this.typeData.map(data => {
        const isMatchId = res.some(typeId => typeId === data.id); // return true or false
        if (isMatchId) {
          return data.isSelected = !data.isSelected;
        }
        return data;
      });
    });
  }

  // toggle language between Thai and Eng
  toggleLanguage(lang: string) {
    if (lang === 'th') {
      this.isThai = true;
      this.isEng = false;
    } else {
      this.isEng = true;
      this.isThai = false;
    }
    this.translate.use(lang);
  }

  // show/hide left side panel
  toToggle() {
    this.toHide = !this.toHide;
    this.mainService_l.toHideandShowPanel.next(this.toHide);
    if (window.innerWidth <= 1024) {
      this.burgerClick.emit(this.toHide);
      (<HTMLInputElement>document.getElementById('button')).disabled = false; // disable submit button
    }
  }

  // store multi selected options
  onSelectTypeData(type: string) {
    // .map can change mapped data
    this.typeData.map(data => {
      if (data.type === type) {
        return data.isSelected = !data.isSelected;
      }
      return data;
    });
    // choose data that `isSelected` equal true --> [ {}, {} ]
    this.selectedOption = this.typeData.filter(data => data.isSelected === true);
    this.mainService_l.selectedType.next(this.selectedOption);

    if (window.innerWidth <= 1024) {
      if (this.selectedOption.length === 0) {
        (<HTMLInputElement>document.getElementById('button')).disabled = true; // disable submit button
      } else {
        (<HTMLInputElement>document.getElementById('button')).disabled = false; // disable submit button
      }
    }

  }

  // when hit search button send selected options to service
  toSearch() {
    // const sample = '';
    // this.onSelectTypeData(sample);
    // this.mainService_l.selectedType.next(this.selectedOption);

    this.toHide = false;
    this.burgerClick.emit(this.toHide);
  }

}
