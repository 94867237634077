import { BrowserModule } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftSideComponent } from './dashboard/left-side/left-side.component';
import { RightMapComponent } from './dashboard/right-map/right-map.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { InfowindowComponent } from './dashboard/infowindow/infowindow.component';
import { AdminComponent } from './admin/admin.component';
import { AppOverlayComponent } from './dashboard/overlay/app.overlay.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SearchThisAreaComponent } from './dashboard/search-this-area/search-this-area.component';

import { HammertimeDirective } from '../hammertime.directive';
import { NoDataModalComponent } from './dashboard/no-data-modal/no-data-modal.component';

import { LinkyModule } from 'ngx-linky';
import { LoginComponent } from './admin/login/login.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': { velocity: 0.3, threshold: 20, direction: Hammer.DIRECTION_ALL } // override default settings
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LeftSideComponent,
    RightMapComponent,
    DashboardComponent,
    InfowindowComponent,
    AppOverlayComponent,
    AdminComponent,
    SearchThisAreaComponent,
    HammertimeDirective,
    NoDataModalComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatButtonModule, MatCheckboxModule, BrowserAnimationsModule, MatProgressBarModule,
    LinkyModule,
    AgmOverlays,
    AgmJsMarkerClustererModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA-D7WUkwnCfQ-FmILxoPte2JDCw2_9XVY'
    }),
    AgmSnazzyInfoWindowModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
